import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import type { ColumnErrors, ColumnMapping, FileMapping, FileUploadMetadata } from 'venn-api';
import type { Theme } from 'venn-ui-kit';
import { GetColor } from 'venn-ui-kit';
import type { BasicTableColumn, BasicTableProps, StyledTableType } from '../../../../basictable/BasicTable';
import { default as BasicTable } from '../../../../basictable/BasicTable';
import { Content, ErrorMessage, FooterButtons, Header } from '../../components/page-parts';
import { DataStatusBar, ErrorEditorRow } from '../../components/review-data';
import { Header as MapDataTableHeader } from '../../components/map-data';
import {
  getGlobalValues,
  getMappingHeaderColumns,
  updateColumnMappingsByGlobalChange,
  updateMappingColumnState,
} from '../mapping/helpers';
import type { DropdownValue } from '../../components/Dropdown';
import {
  createCellRenderer,
  ErrorCellRenderer,
  FundCellRenderer,
  FundLinkerRenderer,
  ObservationsRenderer,
  PerformanceCellRenderer,
} from '../../components/renderers';
import type { DataUploaderMode } from '../../types';
import { APPEND_ONLY } from '../../types';
import { fetchCorrectData, getTypeId } from '../../fetchers';
import type { ErrorViewModel } from './helpers';
import {
  convertColumnErrorsToViewModels,
  countData,
  getColumnsToUpload,
  hasUncorrectedErrors,
  updateErrorValue,
  validateCells,
} from './helpers';
import { Consumer } from '../../context';
import { logMessageToSentry } from 'venn-utils';
import StyledFooterButtons from '../../components/page-parts/StyledFooterButtons';

export interface ReviewProps {
  metadata: FileUploadMetadata;
  mapping: FileMapping;
  errorColumns?: ColumnErrors[];
  onMappingChange: (newMapping: FileMapping) => void;
  onCancel: () => void;
  onStartOver: () => void;
  multiUploaderLayout: boolean;
  onContinue: (mapping: FileMapping, errors: ErrorViewModel[]) => void;
  loading: boolean;
  isSample: boolean;
  /**
   * Custom text to display on the Upload Review screen
   */
  customUploadReviewButtonLabel?: string;
  theme: Theme;
}
interface ReviewPropsWithContext extends ReviewProps {
  mode: DataUploaderMode;
  error: string | React.ReactNode;
}

export interface ReviewState {
  error: string | React.ReactNode; // DataUploader error message, if any
  errors: ErrorViewModel[];
  editingSeriesId?: string;
  hoverRowIndex: number | null;
  mapping: FileMapping;
}

/**
 * Step 2 of the new upload returns process
 */
export class Review extends Component<ReviewPropsWithContext, ReviewState> {
  public debouncedFetchCorrections = debounce(async (requestToken: number, corrections: ErrorViewModel[]) => {
    const {
      mapping: { fileId },
    } = this.state;
    const { errors: newErrors, mapping } = await fetchCorrectData(fileId, corrections);
    if (this.requestToken === requestToken) {
      this.setState((prevState) => {
        const errors = validateCells(convertColumnErrorsToViewModels(newErrors), prevState.errors);
        const hasSeriesIdErrors = hasUncorrectedErrors(errors, prevState.editingSeriesId);

        return {
          errors,
          error: '',
          editingSeriesId: hasUncorrectedErrors(errors) && hasSeriesIdErrors ? prevState.editingSeriesId : undefined,
          mapping,
        };
      });
      // Sync on corrected mapping
      this.props.onMappingChange(mapping);
    }
  }, 250);

  private requestToken = 0;

  constructor(props: ReviewPropsWithContext) {
    super(props);

    const errors = convertColumnErrorsToViewModels(props.errorColumns ?? []);
    this.state = {
      error: props.error,
      errors,
      editingSeriesId: undefined,
      hoverRowIndex: null,
      mapping: props.mapping,
    };
  }

  componentDidUpdate(prevProps: ReviewPropsWithContext) {
    const { error, mapping, errorColumns } = this.props;
    if (prevProps.error !== error) {
      this.setState({ error });
    }

    if (!isEqual(prevProps.errorColumns, errorColumns)) {
      const errors = convertColumnErrorsToViewModels(errorColumns ?? []);
      this.setState({ errors });
    }

    if (!isEqual(prevProps.mapping, mapping)) {
      this.setState({ mapping });
    }
  }

  onTableRowHover = (rowIndex: number, off: boolean) => {
    if (off && this.state.hoverRowIndex === rowIndex) {
      this.setState({ hoverRowIndex: null });
    } else if (!off) {
      this.setState({ hoverRowIndex: rowIndex });
    }
  };

  renderTable(columns: ColumnMapping[]) {
    return (
      <StyledBasicTable
        hideHead
        onRowHoverToggle={this.onTableRowHover}
        data={columns}
        columns={this.getTableColumns(columns)}
        rowHeight={60}
      />
    );
  }

  getTableColumns(columns: ColumnMapping[]): BasicTableColumn<ColumnMapping>[] {
    const { metadata, mode, onMappingChange, theme } = this.props;
    const { errors, editingSeriesId, hoverRowIndex, mapping } = this.state;

    const rendererArgs = {
      mode,
      mapping,
      columns,
      metadata,
      errors,
      colors: theme.Colors,
      onChange: onMappingChange,
    };

    return [
      {
        cellStyle: { width: 60 },
        cellRenderer: createCellRenderer.call(null, {
          renderer: FundLinkerRenderer,
          ...rendererArgs,
        }),
      },
      {
        cellStyle: { width: 300 },
        cellRenderer: createCellRenderer.call(null, {
          renderer: FundCellRenderer,
          ...rendererArgs,
        }),
      },
      {
        cellStyle: { width: 150 },
        cellRenderer: createCellRenderer.call(null, {
          renderer: ObservationsRenderer,
          ...rendererArgs,
        }),
      },
      {
        cellStyle: { width: 240, overflowX: 'hidden' },
        cellRenderer: createCellRenderer.call(null, {
          renderer: PerformanceCellRenderer,
          ...rendererArgs,
        }),
      },
      {
        cellStyle: { width: 250 },
        cellRenderer: createCellRenderer.call(null, {
          renderer: ErrorCellRenderer,
          editingSeriesId,
          toggleEditing: this.toggleEditing,
          hoverRowIndex,
          ...rendererArgs,
        }),
      },
    ];
  }

  toggleEditing = (seriesId: string) => {
    const { editingSeriesId } = this.state;
    if (editingSeriesId === seriesId) {
      return this.setState({ editingSeriesId: undefined });
    }
    return this.setState({ editingSeriesId: seriesId });
  };

  submitCorrections = (corrections: ErrorViewModel[]) => {
    const requestToken = this.requestToken + 1;
    this.requestToken = requestToken;
    this.debouncedFetchCorrections(requestToken, corrections);
  };

  updateErrorValue = (error: ErrorViewModel, value: string) => {
    this.setState((prevState) => ({ errors: updateErrorValue(prevState.errors, error, value) }));
  };

  onContinue = () => this.props.onContinue(this.state.mapping, this.state.errors);

  onHeaderDropdownChange = (columns: ColumnMapping[], values: DropdownValue[]) => {
    const { mapping } = this.state;
    const newMapping = updateMappingColumnState({ mapping, columns, values }, updateColumnMappingsByGlobalChange);
    this.props.onMappingChange(newMapping);
  };

  renderTables(columns: ColumnMapping[]) {
    const { metadata, mode } = this.props;
    const { editingSeriesId, errors } = this.state;
    const mappingColumn = columns.find((c) => c.seriesId === editingSeriesId);
    const priceId = getTypeId(metadata, 'metricType', 'Price');

    if (editingSeriesId && mappingColumn) {
      const isNew = !mappingColumn.fundId;

      const { globalCurrency, globalIsPercent, globalMetricId, globalAppendId } = getGlobalValues(
        [mappingColumn],
        mode,
      );
      const isPrice = globalMetricId === priceId;

      const headerValues: DropdownValue[] = [
        globalMetricId as DropdownValue,
        globalIsPercent as DropdownValue,
        globalCurrency as DropdownValue,
      ];

      return (
        <>
          <FundsSection>
            <MapDataTableHeader
              mode={mode}
              columns={getMappingHeaderColumns(metadata, {
                mode,
                isNew,
                readonly: true,
                isPrice,
              })}
              values={isNew ? headerValues : headerValues.concat(globalAppendId as DropdownValue)}
              marginLeft={38}
            />
            {this.renderTable([mappingColumn])}
          </FundsSection>
          {errors
            .filter((e) => e.seriesId === editingSeriesId)
            .map((error) => {
              const { rowIndex, seriesId, value, date } = error;
              const column = columns.find((c) => c.seriesId === seriesId);
              if (column === undefined) {
                logMessageToSentry('Failed to log error editor row.');
                return null;
              }

              return (
                <ErrorEditorRow
                  key={`${rowIndex}-${column.origin.colIndex}`}
                  cell={{
                    // Negative rowIndex means it doesn't exist so don't show it,
                    // and the rowIndex doesn't take into account the header row so add 1
                    row: rowIndex >= 0 ? rowIndex + 1 : undefined,
                    column: column.origin.colIndex,
                    value,
                  }}
                  date={date}
                  valid={error.isValid}
                  errors={error.errors.map((e) => metadata.errorTypes.find((t) => t.id === e)?.name ?? '')}
                  onChange={(newValue) => this.updateErrorValue(error, newValue)}
                  onBlur={() => this.submitCorrections(this.state.errors)}
                  isPrice={isPrice}
                />
              );
            })}
        </>
      );
    }

    const globalValues = getGlobalValues(columns, mode);
    const headerValues: DropdownValue[] = [
      globalValues.globalMetricId as DropdownValue,
      globalValues.globalIsPercent as DropdownValue,
      globalValues.globalCurrency as DropdownValue,
    ];
    const hasExistingColumns = countData(columns, mode, false) > 0;
    if (hasExistingColumns) {
      headerValues.push(globalValues.globalAppendId as DropdownValue);
    }
    const hasNewColumns = countData(columns, mode, true) > 0;
    const appendOnlyId = getTypeId(metadata, 'appendType', APPEND_ONLY);
    const appendOnly = hasExistingColumns && globalValues.globalAppendId === appendOnlyId;
    const isPrice = globalValues.globalMetricId === priceId;

    return (
      <>
        <MapDataTableHeader
          mode={mode}
          columns={getMappingHeaderColumns(metadata, {
            mode,
            isNew: !hasExistingColumns,
            // disable other dropdowns only when it's both append only and there are no new columns
            appendOnly: appendOnly && !hasNewColumns,
            isPrice,
          })}
          values={headerValues}
          onChange={(values: DropdownValue[]) => this.onHeaderDropdownChange(columns, values)}
          marginLeft={38}
        />
        {this.renderTable(columns)}
      </>
    );
  }

  render() {
    const { metadata, mode, onCancel, onStartOver, loading, isSample, multiUploaderLayout } = this.props;
    const {
      error,
      errors,
      mapping: { columns },
    } = this.state;
    const columnsToUpload = getColumnsToUpload(columns, metadata, errors);

    const uploadButtonLabel = this.props.customUploadReviewButtonLabel ?? 'Add to Library';
    const headerInnerComponent = (
      <>
        <HeaderInner>
          <div>
            <h1>Review &amp; Validate Data</h1>
            <h2>Ensure data is mapped correctly.</h2>
          </div>
        </HeaderInner>
        <StatusBarContainer>
          <DataStatusBar
            newCount={countData(columnsToUpload, mode, true)}
            updateCount={countData(columnsToUpload, mode, false)}
          />
        </StatusBarContainer>
      </>
    );
    return multiUploaderLayout ? (
      <MainWrapper>
        {!!error && <ErrorMessage>{error}</ErrorMessage>}
        <StyledContent>{this.renderTables(columns)}</StyledContent>
        <StyledFooterButtons
          className="review-footer-btn-container"
          onCancel={onCancel}
          onStartOver={onStartOver}
          onContinue={this.onContinue}
          disabled={loading}
          hasNoData={!columnsToUpload.length}
          isSample={isSample}
          primaryLabel={uploadButtonLabel}
        />
      </MainWrapper>
    ) : (
      <>
        <Header>
          {headerInnerComponent}
          {!!error && <ErrorMessage>{error}</ErrorMessage>}
        </Header>
        <StyledContent>{this.renderTables(columns)}</StyledContent>
        <FooterButtons
          onCancel={onCancel}
          onStartOver={onStartOver}
          onContinue={this.onContinue}
          disabled={loading}
          hasNoData={!columnsToUpload.length}
          isSample={isSample}
          primaryLabel={uploadButtonLabel}
        />
      </>
    );
  }
}

export default withTheme((props: ReviewProps) => (
  <Consumer>{({ mode, error }) => <Review {...props} mode={mode} error={error} />}</Consumer>
));

const StyledBasicTable: StyledTableType<unknown> = styled(
  <T extends BasicTableColumn<K>, K>(props: BasicTableProps<T, K>) => <BasicTable<T, K> {...props} />,
)`
  > thead > tr {
    border-bottom-color: ${GetColor.DarkGrey};
  }

  > tbody,
  > thead {
    > tr {
      > th {
        color: ${GetColor.DarkGrey};
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 0;
        padding-right: 0;

        &:first-child {
          padding-left: 15px;
        }
      }

      > td {
        padding: 0px 0;
        border-bottom: 1px solid ${GetColor.PaleGrey};
      }
    }
  }
`;

const HeaderInner = styled.div`
  display: flex;
`;

const StyledContent = styled(Content)`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  section + section {
    margin-top: 30px;
  }
`;

const StatusBarContainer = styled.div`
  padding: 7px 0 0;
`;

const FundsSection = styled.section`
  width: 998px;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;
