import React, { useState } from 'react';
import type { MultiPortfolioParseResult } from 'venn-api';
import styled from 'styled-components';
import { isEmpty, isNil, partition } from 'lodash';
import { GetColor } from 'venn-ui-kit';
import { ScrollableSectionWithStickyHeader } from './review/ScrollableSectionWithStickyHeader';

type MultiPortfolioReviewStepProps = Readonly<{ parsedData: MultiPortfolioParseResult }>;

export const MultiPortfolioReviewStep = ({ parsedData }: MultiPortfolioReviewStepProps) => {
  const [newParsedData, existingParsedData] = partition(
    parsedData.portfolioParseResults.map((data, index) => {
      return {
        ...data,
        originalIndex: index,
      };
    }),
    (data) => isNil(data.parsedPortfolio.id),
  );

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  return (
    <MainWrapper>
      <LeftContainer>
        <ScrollableSectionWithStickyHeader
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          groupIndex={0}
          groupReverseIndex={isEmpty(newParsedData) ? 0 : 1}
          parsedResults={existingParsedData}
          title="EXISTING PORTFOLIOS"
        />
        <ScrollableSectionWithStickyHeader
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          groupReverseIndex={0}
          groupIndex={isEmpty(existingParsedData) ? 0 : 1}
          parsedResults={newParsedData}
          title="NEW PORTFOLIOS"
        />
      </LeftContainer>
      <pre>{JSON.stringify(parsedData.portfolioParseResults[selectedIndex], null, 2)}</pre>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  height: calc(100vh - 180px);
  ::-webkit-scrollbar {
    display: none;
  }
  overflow: auto;
  align-items: flex-start;
  align-self: stretch;
  border-right: 1px solid ${GetColor.GreyScale.Grey30};
`;
