import type { FC } from 'react';
import React from 'react';
import styled from 'styled-components';
import { ModalFooter } from '../../../../modal';
import { GetColor } from 'venn-ui-kit';

interface FooterButtonsProps {
  disabled?: boolean;
  disabledMessage?: React.ReactNode;
  onContinue: () => void;
  onCancel: () => void;
  onStartOver: () => void;
  hasNoData?: boolean;
  isSample?: boolean;
  primaryLabel?: string;
  className?: string;
}

const FooterButtons: FC<React.PropsWithChildren<FooterButtonsProps>> = ({
  disabled,
  disabledMessage,
  onContinue,
  onCancel,
  onStartOver,
  hasNoData,
  isSample,
  primaryLabel,
  className,
}) => {
  return (
    <FooterContainer className={className}>
      <StyledModalFooter
        className="no-margin-footer"
        cancelClassName="qa-upload-cancel"
        onCancel={isSample ? undefined : onCancel}
        rightChildren={disabledMessage}
        primaryLabel={hasNoData ? '' : primaryLabel ?? 'Complete Upload'}
        primaryClassName="qa-accept-btn"
        primaryDisabled={disabled}
        onPrimaryClick={onContinue}
        secondaryLabel={isSample ? 'Try with Your Data' : 'Start Over'}
        onSecondaryClick={onStartOver}
      />
    </FooterContainer>
  );
};

export default FooterButtons;

const FooterContainer = styled.div`
  border-radius: 0;
  width: 100%;
  .no-margin-footer {
    margin-top: 0;
  }
`;

const StyledModalFooter = styled(ModalFooter)`
  width: 100%;
  background-color: ${GetColor.White};
`;
