import { isNil } from 'lodash';
import React, { useState } from 'react';
import type { MultiPortfolioParseResult } from 'venn-api';
import { MultiPortfolioReviewStep } from './MultiPortfolioReviewStep';
import MultiPortfolioUploadStep from './MultiPortfolioUploadStep';

const MultiPortfolioUploader = () => {
  const [parsedData, setParsedData] = useState<MultiPortfolioParseResult>();

  if (isNil(parsedData)) {
    return <MultiPortfolioUploadStep setParsedData={setParsedData} />;
  }
  return <MultiPortfolioReviewStep parsedData={parsedData} />;
};

export default MultiPortfolioUploader;
