import React from 'react';
import {
  IDuplicatePortfolioView,
  INewPortfolioView,
  IUploadPortfolioView,
  type PortfolioMultiUploaderView,
} from '../types';
import { OptionTile } from './shared/tiles';
import { LeftContainer, MainViewContainer, SectionContainer } from './shared/layout';

type ChooseActionViewProps = {
  setView: (view: PortfolioMultiUploaderView) => void;
};

export const ChoosePortfolioActionView = ({ setView }: ChooseActionViewProps) => {
  const options: PortfolioMultiUploaderView[] = [INewPortfolioView, IDuplicatePortfolioView, IUploadPortfolioView];

  return (
    <MainViewContainer>
      <LeftContainer>
        <SectionContainer key="portfolios-section">
          {options.map((option) => (
            <OptionTile onClick={() => setView(option)} option={option} key={option.id} />
          ))}
        </SectionContainer>
      </LeftContainer>
    </MainViewContainer>
  );
};
