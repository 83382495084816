import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { type MultiPortfolioParseResult, parseFile, TABS } from 'venn-api';
import { GetColor } from 'venn-ui-kit';
import UniversalUploaderContext from '../../../contexts/universal-uploader';
import ModalFooter from '../../../modal/ModalFooter';
import SideMenu from '../../../side-menu/SideMenu';
import DataGallery from '../components/DataGallery';
import DataInput from '../components/DataInput';
import { ErrorMessage } from '../components/page-parts';
import PasteInput from '../components/PasteInput';
import { DataUploaderMode, IChooseActionPortfolioView, IReviewPortfolioView } from '../types';

type MultiPortfolioUploadStepProps = Readonly<{
  setParsedData: (data: MultiPortfolioParseResult) => void;
}>;

const getTabs = () => {
  return [
    {
      label: 'Upload Portfolio Data',
      value: TABS.UPLOAD,
    },
    {
      label: 'Paste Portfolio Data',
      value: TABS.PASTE,
    },
    {
      label: 'Formats and Templates',
      value: TABS.TEMPLATES,
    },
  ];
};

const MultiPortfolioUploadStep = ({ setParsedData }: MultiPortfolioUploadStepProps) => {
  const [tab, setTab] = useState(TABS.UPLOAD);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const { setView } = useContext(UniversalUploaderContext);

  const uploadFile = async (file: Blob) => {
    setErrorMessage('');
    setIsUploading(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const parsedData = (await parseFile(formData)).content;
      setParsedData(parsedData);
      setView(IReviewPortfolioView);
    } catch (e) {
      setErrorMessage(e?.content?.message ?? 'Unable to process file. Please try again or contact Venn Support.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <MainWrapper>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <MainRow>
        <SideBar data-testid="sidebar">
          <SideMenu items={getTabs()} onClick={setTab} selectedItems={tab} withoutContainer allowItemOverflow />
        </SideBar>
        <Content>
          {tab === TABS.UPLOAD && (
            <DataInput
              onFileChange={uploadFile}
              loading={isUploading}
              setError={setErrorMessage}
              mode={DataUploaderMode.Portfolios}
            />
          )}
          {tab === TABS.PASTE && (
            <PasteInput onPaste={uploadFile} mode={DataUploaderMode.Portfolios} isUploading={isUploading} />
          )}
          {tab === TABS.TEMPLATES && <DataGallery mode={DataUploaderMode.Portfolios} isUniversalUploader />}
        </Content>
      </MainRow>
      <StyledModalFooter cancelLabel="Back" onCancel={() => setView(IChooseActionPortfolioView)} />
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  margin: 0 20px 0 15px;
  width: 100%;
  flex: 1;
`;

const SideBar = styled.div`
  width: 180px;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MainRow = styled(FlexRow)`
  margin-top: 10px;
  width: 100%;
  height: 100%;
  flex: 1;
`;

const StyledModalFooter = styled(ModalFooter)`
  width: 100%;
  background-color: ${GetColor.White};
`;

export default MultiPortfolioUploadStep;
