import styled from 'styled-components';

export const MainViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px 0 0 24px;
  max-width: 600px;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const RightContainer = styled.div`
  display: flex;
  max-width: 500px;
  max-height: calc(100vh - 200px);
`;

export const StyledImage = styled.img`
  width: 100%;
  margin-top: 20px;
`;
