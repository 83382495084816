export const APPEND_ONLY = 'Append Only';
export const DO_NOT_MAP = 'Do Not Map';
export const FUND = 'Fund';

export enum AppendType {
  OVERWRITE_ALL = 1,
  OVERWRITE_OVERLAPPING,
  APPEND,
}

export enum DataUploaderMode {
  Returns,
  NewNavs,
  Initial,
  Privates,
  Portfolios,
}

// assign string values just so that number values won't incorrectly be used as the step number
export enum DataUploaderView {
  Upload = 'Upload',
  Review = 'Review',
  DisplayNavMapping = 'DisplayNavMapping',
  Initial = 'Initial',
}

export interface DataCell {
  // 0-indexed
  row?: number;
  column: number;
  value: string;
}

export interface DataPoint {
  x: number;
  y: number;
}

export const DO_NOT_MAP_ID = 1;

export type PortfolioMultiUploaderProps = {
  onClose: () => void;
  lastTimeBeforeUpdate?: number;
};

export type MultiUploaderReviewViewId = 'REVIEW_RETURNS' | 'REVIEW_PRIVATES';
export type MultiUploaderUploadViewId = 'UPLOAD_RETURNS' | 'UPLOAD_PRIVATES';
type MultiUploaderChooseActionView = 'CHOOSE_ACTION' | 'CHOOSE_ACTION_PORTFOLIO';

type PortfolioMultiUploaderViewId =
  | MultiUploaderReviewViewId
  | MultiUploaderUploadViewId
  | MultiUploaderChooseActionView
  | 'UPLOAD_PORTFOLIO'
  | 'REVIEW_PORTFOLIO'
  | 'DUPLICATE_PORTFOLIO'
  | 'NEW_PORTFOLIO'
  | 'CREATE_PRIVATE_PORTFOLIO';

export interface PortfolioMultiUploaderView {
  id: PortfolioMultiUploaderViewId;
  title: string;
  description: string | undefined;
  highlightText: string | undefined;
}

export const IChooseActionView = {
  id: 'CHOOSE_ACTION',
  title: 'Create and update portfolios and investments',
  description: undefined,
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const IUploadReturnsView = {
  id: 'UPLOAD_RETURNS',
  title: 'Upload investment return data',
  description: 'Add new or update existing returns-based investment(s)',
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const IReviewReturnsView = {
  id: 'REVIEW_RETURNS',
  title: 'Review and validate returns data',
  description: undefined,
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const IUploadPrivatesView = {
  id: 'UPLOAD_PRIVATES',
  title: 'Upload private asset data',
  description:
    'Add new or update existing private asset investment(s), including cash flows and other private asset metadata',
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const IReviewPrivatesView = {
  id: 'REVIEW_PRIVATES',
  title: 'Review and validate private asset data',
  description: undefined,
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const IChooseActionPortfolioView = {
  id: 'CHOOSE_ACTION_PORTFOLIO',
  title: 'Create or update pro forma portfolio(s)',
  description: 'Pro forma portfolio using static allocations, used for returns-based analytics',
  highlightText: 'Now upload multiple portfolios from a single file!',
} as const satisfies PortfolioMultiUploaderView;

export const IUploadPortfolioView = {
  id: 'UPLOAD_PORTFOLIO',
  title: 'Upload portfolio data',
  description:
    'Update existing or create new portfolio(s) by uploading NAVs and strategy hierarchies from a spreadsheet.',
  highlightText: 'Upload multiple portfolios’ NAVs & strategy hierarchies in a single file!',
} as const satisfies PortfolioMultiUploaderView;

export const IReviewPortfolioView = {
  id: 'REVIEW_PORTFOLIO',
  title: 'Preview and adjust portfolio data',
  description: undefined,
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const IDuplicatePortfolioView = {
  id: 'DUPLICATE_PORTFOLIO',
  title: 'Duplicate an existing portfolio',
  description: 'Copy existing portfolio as a starting point to edit further',
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const INewPortfolioView = {
  id: 'NEW_PORTFOLIO',
  title: 'Build a new portfolio in Analysis',
  description: "Build your portfolio by adding investments from Venn's Data Library and assigning allocations",
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;

export const ICreatePrivatePortfolioView = {
  id: 'CREATE_PRIVATE_PORTFOLIO',
  title: 'Create new private asset portfolio',
  description: 'Contains only private asset data and is used for private asset-specific analytics',
  highlightText: undefined,
} as const satisfies PortfolioMultiUploaderView;
