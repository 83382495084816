import React, { useContext, useRef } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import classNames from 'classnames';
import { ButtonIcon, ColorUtils, Headline3, RelativePortal, ZIndex } from 'venn-ui-kit';
import FocusTrap from 'focus-trap-react';
import ReactDOM from 'react-dom';

const CLOSE_PANEL_CLASSNAME = 'qa-close-panel';
export const SIDE_PANEL_CONTENT_CLASSNAME = 'side-panel-content';

const getAnimationClassName = (isOpen: boolean, hasOpened: boolean, side: 'left' | 'right') => {
  if (isOpen) {
    return 'slide-in';
  }
  return hasOpened ? `slide-out-${side}` : '';
};

interface SidePanelOverlayProps {
  /** Which side of the page the panel is located */
  side: 'left' | 'right';
  /** The title on the panel */
  title?: JSX.Element;
  /** The content of th panel after the title */
  content?: JSX.Element;
  /** The footer of the panel */
  footer?: JSX.Element;

  /** Callback when the panel is closed */
  handleClose(): void;

  /** The width of the panel */
  width: number;
  /** The width of the left margin */
  margin?: number;
  /** Whether the panel is open/visible or not */
  isOpen: boolean;
  /** Class name for the panel */
  className?: string;
  /**
   * Element to focus on when the panel opens.
   * If not provided, the focus will be on the first element in the panel, i.e. the button
   * to close the panel.
   */
  focusOnOpen?: React.MutableRefObject<HTMLElement | undefined>;
  /** Element to append Panel to */
  mainContentElement: HTMLElement;
  hideTopBackButton?: boolean;
  scrollAlignRight?: boolean;
  noPadding?: boolean;
}

/**
 * A panel that opens and closes (with animation) from the left or the right,
 * and overlays the main content. When opened, focus is by default brought to the panel,
 * and when closed, the focus is brought back to the last focused element before the panel was opened.
 */
const SidePanelOverlay = ({
  className,
  side,
  title,
  content,
  footer,
  handleClose,
  width,
  margin = 65,
  isOpen,
  focusOnOpen,
  mainContentElement,
  scrollAlignRight,
  hideTopBackButton = false,
  noPadding = false,
}: SidePanelOverlayProps) => {
  const { Colors } = useContext(ThemeContext);

  // make sure slide-out animation doesn't happen if it's never opened yet, e.g. on first render
  const hasOpened = useRef(false);
  if (isOpen) {
    hasOpened.current = true;
  }

  const animationClassName = getAnimationClassName(isOpen, hasOpened.current, side);
  const panelStyle = {
    width,
    maxWidth: '100vw',
    backgroundColor: Colors.White,
    transform: `translateX(${side === 'right' ? '' : '-'}100vw)`,
    animation: `${animationClassName} 0.5s forwards`,
    zIndex: ZIndex.InterComFront,
  };

  const bodyContentElement =
    document.getElementsByTagName(
      'body',
    )[0]; /* we append overlay element to the body so that it covers the entire screen */

  return (
    <Keyframes>
      {!!mainContentElement &&
        ReactDOM.createPortal(<Overlay style={isOpen ? undefined : { display: 'none' }} />, bodyContentElement)}
      <RelativePortal
        fullHeight
        rightOffset={side === 'right' ? 0 : undefined}
        relativeElement={mainContentElement}
        style={panelStyle}
        className={classNames(className, animationClassName)}
      >
        {isOpen && (
          <FocusTrap
            active={isOpen}
            focusTrapOptions={{
              initialFocus: focusOnOpen ? () => focusOnOpen.current! : undefined,
              fallbackFocus: CLOSE_PANEL_CLASSNAME,
              allowOutsideClick: true,
            }}
          >
            <Panel>
              <PanelContainer noPadding={noPadding} scrollAlignRight={scrollAlignRight}>
                {!hideTopBackButton && (
                  <BackButtonContainer panelSide={side}>
                    <ButtonIcon
                      className={CLOSE_PANEL_CLASSNAME}
                      dominant
                      iconType={`arrow-${side}`}
                      onClick={handleClose}
                    />
                  </BackButtonContainer>
                )}
                <PanelContent side={side} margin={margin}>
                  {title && <Headline3>{title}</Headline3>}
                  <InnerContent className={SIDE_PANEL_CONTENT_CLASSNAME}>{content}</InnerContent>
                </PanelContent>
              </PanelContainer>
              {footer && <Footer>{footer}</Footer>}
            </Panel>
          </FocusTrap>
        )}
      </RelativePortal>
    </Keyframes>
  );
};

export default SidePanelOverlay;

const Keyframes = styled.div`
  @keyframes slide-in {
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slide-out-left {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100vw);
    }
  }

  @keyframes slide-out-right {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100vw);
    }
  }
`;

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PanelContainer = styled.div<{ noPadding: boolean; scrollAlignRight?: boolean }>`
  ${({ scrollAlignRight, noPadding }) =>
    noPadding
      ? css`
          padding: 0;
        `
      : scrollAlignRight
        ? css`
            padding: 30px 0 0 30px;
          `
        : css`
            padding: 30px 30px 0 30px;
          `}

  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const PanelContent = styled.div<Pick<SidePanelOverlayProps, 'side' | 'margin'>>`
  padding-left: ${({ side, margin }) => (side === 'right' ? margin : undefined)}px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
`;

const InnerContent = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const BackButtonContainer = styled.div<{ panelSide: 'left' | 'right' }>`
  width: 100%;
  display: flex;
  ${({ panelSide }) => panelSide === 'left' && 'justify-content: flex-end'};
  margin-bottom: 25px;
`;

const Footer = styled.footer`
  box-shadow: 0px -2px 2px ${ColorUtils.opacify('#000', 0.06)};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`;

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: rgba(16, 22, 27, 0.75);
  z-index: ${ZIndex.Modal};
`;
